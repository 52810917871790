import RootLayout from '@/layouts/root';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import '@/styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import React from 'react';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { useSessionUser } from '@/hooks/useSessionUser';
import Loading from '@/components/Loading/Loading';
import { AudioProvider } from '@/context/AudioContext';
import { useRouter } from 'next/router';
import SettingModal from '@/components/_modals/SettingModal';
import { useSessionUserStore } from '@/hooks/stores/useSessionUserStore';

const App = ({ Component, pageProps }: AppProps) => {
  const { isFetching, user, loginError, login, setUser } = useSessionUser();
  const { modalIds } = useSessionUserStore();
  const { i18n } = useTranslation();
  const [displaySettingModal, setDisplaySettingModal] = React.useState(false);
  const idsRef = React.useRef<string[]>([]);
  idsRef.current = modalIds;

  const setWindowMode = () => {
    const mode = localStorage.getItem('windowMode');
    if (mode === 'windowed') {
      window.electronAPI.changeWindowMode('windowed');
    }
  };

  const handleKeydownEsc = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      if (idsRef.current.length > 0) return;

      setDisplaySettingModal(true);
    }
  };

  const handleCloseSettingModal = () => {
    setDisplaySettingModal(false);
  };

  React.useEffect(() => {
    let region = 'jp';
    const setLanguage = async () => {
      // const storedRegion = localStorage.getItem('region');
      // if (storedRegion) {
      //   region = storedRegion;
      // } else {
      //   region = await window.electronAPI.getCountryCode();
      // }
      localStorage.setItem('region', region);
      i18n.changeLanguage(region);
    };

    const initialLogin = async () => {
      await setLanguage();
      await login(region);
    };

    initialLogin();
    setWindowMode();

    document.addEventListener('keydown', handleKeydownEsc);

    return () => {
      document.removeEventListener('keydown', handleKeydownEsc);
    };
  }, []);

  return (
    <RootLayout>
      {/* <Head>
        <title>みんなで推理</title>
      </Head> */}
      <div className="relative bg-[url('/images/bg.png')] h-full bg-cover bg-center bg-no-repeat">
        {isFetching && <Loading title="Loading" size={8} />}
        {user && (
          <AudioProvider>
            <Component {...pageProps} />
            {displaySettingModal && (
              <SettingModal onClose={handleCloseSettingModal} />
            )}
          </AudioProvider>
        )}
        {loginError && (
          <AudioProvider>
            <Component {...pageProps} />
          </AudioProvider>
        )}
      </div>
    </RootLayout>
  );
};

export default appWithTranslation(App);
