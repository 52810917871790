export const mono = {
  0: '#fff',
  50: '#f2f2f2',
  100: '#e3e3e3',
  200: '#c4c4c4',
  300: '#9e9e9e',
  400: '#757575',
  500: '#5c5c5c',
  600: '#454545',
  700: '#333333',
  800: '#262626',
  900: '#1c1c1c',
  1000: '#000',
};
