import * as React from 'react';

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  width?: string;
  height?: string;
  children: React.ReactNode;
}

const Select = React.forwardRef<HTMLSelectElement, Props>(
  ({ width, height, children, ...props }, ref) => {
    const defaultClassName =
      'truncate custom-select p-2 pl-3 overflow-y-hidden input-custom text-white text-2xl rounded-lg block w-auto' +
      ' ' +
      props.className;

    return (
      <div
        className="border-2 border-gold-300 rounded-full"
        style={{ width, height }}
      >
        <select {...props} className={defaultClassName} ref={ref}>
          {children}
        </select>
      </div>
    );
  },
);

Select.displayName = 'Select'; // forwardRefを使う際はdisplayNameを設定するのが推奨されます

export default Select;
