import * as React from 'react';
import { useSessionUserStore } from './stores/useSessionUserStore';

type ReturnProps = [
  string[],
  (id: string) => void,
  (id: string) => void,
  (id: string) => boolean,
];

export function useModalOrders(): ReturnProps {
  const { modalIds, setModalIds } = useSessionUserStore();

  const addModal = React.useCallback(
    (id: string) => {
      setModalIds([...modalIds, id]);
    },
    [modalIds, setModalIds],
  );

  const removeModal = React.useCallback(
    (id: string) => {
      const removedModalIds = modalIds.filter((v) => v !== id);
      setModalIds(removedModalIds);
    },
    [modalIds, setModalIds],
  );

  const isForeground = React.useCallback(
    (id: string) => {
      return modalIds[modalIds.length - 1] === id;
    },
    [modalIds],
  );

  return [modalIds, addModal, removeModal, isForeground];
}
