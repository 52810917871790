import { Kaisei_Opti } from 'next/font/google';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const kaisei = Kaisei_Opti({ weight: '400', subsets: ['latin'] });

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className={`${kaisei.className} bg-darkGreen-900 h-full`}>
      {children}
      <ToastContainer
        position="bottom-left"
        toastClassName={`${kaisei.className}`}
        transition={Zoom}
        autoClose={3000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        icon={({ type }) => {
          if (type === 'success')
            return <img src="/images/info_icon_green.png" />;
          if (type === 'error') return <img src="/images/info_icon_red.png" />;
        }}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
