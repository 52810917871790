import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface RoomStoreState {
  roomId?: string;
  setRoomId: (roomId?: string) => void;
}

export const useRoomStore = create<RoomStoreState>()(
  devtools((set) => ({
    roomId: undefined,
    setRoomId: (roomId?: string) => set(() => ({ roomId })),
  })),
);
