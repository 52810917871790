import * as React from 'react';
import Select from '../Select';

const options = [
  {
    label: '日本',
    value: 'jp',
  },
  {
    label: 'English',
    value: 'us',
  },
  {
    label: '한국어',
    value: 'kr',
  },
];

export default function ServerSettings() {
  const [language, setLanguage] = React.useState('jp');

  const getCountryCode = async () => {
    const region = await window.electronAPI.getCountryCode();
    const isAvailableRegion = options.find((option) => option.value === region);
    if (isAvailableRegion) {
      setLanguage(region);
    } else {
      setLanguage('us');
    }
  };

  const handleChangeMode = async (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    await window.electronAPI.changeServer(event.target.value);
  };

  React.useEffect(() => {
    getCountryCode();
  }, []);

  return (
    <div className="max-w-[200px]">
      <Select
        className="text-base"
        value={language}
        onChange={handleChangeMode}
      >
        {options.map((option) => (
          <option
            key={option.value}
            className="text-black"
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </Select>
    </div>
  );
}
