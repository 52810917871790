import * as React from 'react';
import { PulseLoader } from 'react-spinners';

interface Props {
  size?: number;
  title?: string;
}

export default function Loading({ size = 12, title }: Props) {
  return (
    <div
      className={`fixed w-full h-full z-10`}
      style={{ backgroundColor: `rgba(0,0,0,0.6)` }}
    >
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-end gap-2 justify-center">
        {title && (
          <span className="text-white text-xl text-center">{title}</span>
        )}
        <PulseLoader color="#48532c" size={size} />
      </div>
    </div>
  );
}
