import { Report } from '@/types/question';
import { QuestionRoom } from '@/types/rooms';
import { SteamUser } from '@/types/user';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface SessionUserStoreState {
  user: (SteamUser & { rooms?: QuestionRoom[] }) | null;
  reports: Report[];
  modalIds: string[];
  isFetching: boolean;
  isCompleted: boolean;
  setUser: (user: SteamUser | null) => void;
  setModalIds: (modalIds: string[]) => void;
  setFetching: (isFetching: boolean) => void;
  setReports: (reports: Report[]) => void;
  setCompleted: (isCompleted: boolean) => void;
}

export const useSessionUserStore = create<SessionUserStoreState>()(
  devtools((set) => ({
    user: null,
    reports: [],
    setUser: (user: SteamUser | null) =>
      set(() => ({ user, isCompleted: true })),
    setReports: (reports: Report[]) => set(() => ({ reports })),
    setCompleted: (isCompleted: boolean) => set(() => ({ isCompleted })),
    modalIds: [],
    setModalIds: (modalIds: string[]) =>
      set((state) => ({ modalIds: modalIds })),
    isFetching: false,
    setFetching: (isFetching: boolean) => set(() => ({ isFetching })),
    isCompleted: false,
  })),
);
