import * as React from 'react';
import { useTranslation, Trans } from 'next-i18next';

import Select from '../Select';

type WindowModeType = 'fullscreen' | 'windowed';

export default function WindowSettings() {
  const [windowMode, setWindowMode] = React.useState('fullscreen');
  const { t } = useTranslation();

  const handleChangeMode = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const mode = event.target.value as WindowModeType;
    setWindowMode(mode);
    localStorage.setItem('windowMode', mode);
    window.electronAPI.changeWindowMode(mode);
  };

  React.useEffect(() => {
    const mode = localStorage.getItem('windowMode');
    if (!mode || (mode !== 'fullscreen' && mode !== 'windowed')) {
      setWindowMode('fullscreen');
      localStorage.setItem('windowMode', 'fullscreen');
    } else {
      setWindowMode(mode);
      localStorage.setItem('windowMode', mode);
    }
  }, []);

  return (
    <div className="max-w-[200px]">
      <Select
        className="text-base"
        value={windowMode}
        onChange={handleChangeMode}
      >
        <option className="text-black" value="fullscreen">
          {t('options.window.fullScreen')}
        </option>
        <option className="text-black" value="windowed">
          {t('options.window.windowed')}
        </option>
      </Select>
    </div>
  );
}
