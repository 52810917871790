import { mono } from './palettes';

export const appColor = {
  primary: '#3ea0f3',
  success: '#18981D',
  successFill: '#18981D',
  successBackground: '#C1F2C7',
  warning: '#F0B429',
  warningFill: '#F0B429',
  warningBackground: '#FFF3C4',
  warningText: '#B44D12',
  error: '#E12D39',
  errorFill: '#E12D39',
  errorBackground: '#FEE2E2',
  background: mono[100],
  backgroundLight: mono[50],
  text: {
    black: mono[1000],
    darkGray: mono[600],
    placeholder: mono[300],
    inactive: mono[500],
    annotation: mono[400],
    link: '#3ea0f3',
    linkVisited: mono[300],
    saturday: '#206cd5',
    sunday: '#B9111C',
  },
  border: {
    normal: mono[200],
    light: mono[100],
    dark: mono[300],
  },
  icon: {
    normal: mono[600],
  },
  gray: mono[300],
  darkGray: mono[600],
  lightGray: mono[100],
  white: mono[0],
  lineGreen: '#06c755',
};
